import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Tooltip, Space, Select } from 'antd';
import {  Link, useParams } from 'react-router-dom';
import { getTedTalksById } from '../../apis/fixture.api';
import Loader from '../../components/common/Loader';
import VideoPlayer from '../VideoPlayer';
import { talksInterface } from '../interfaces';
import Title from 'antd/es/typography/Title';
import { styles } from '../../styles';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import Comments from '../Comments';
import SearchBar from '../../components/common/Search';
import IFramePlayer from '../IFramePlayer';
import './index.css'
import Episodes from './episodes';
import { getPageTitle } from '../../utils/pageTitle';

export default function TalkDetail() {
    const { categoryName, id } = useParams();
    const matches = useMediaQuery('(min-width: 768px)');
    const [videoDetails, setVideoDetails] = useState<talksInterface>();
    const [iframeURL, setIframeURL] = useState<undefined | string>('');;
    const [relatedTalks, setRelatedTalks] = useState<talksInterface[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const getVideoDetails = async () => {
        setLoader(true);
        const parts = id?.split('-'); // Splits the string into an array
        const validId = parts && parts[parts?.length - 1];
        const response = await getTedTalksById({id: validId, category: categoryName});
        const currentURL = response?.data?.talk?.embedUrl ?? response?.data?.talk?.iframeURL;
        document.title = getPageTitle(undefined, undefined, response?.data?.talk?.title);
        setIframeURL(currentURL);
        setVideoDetails(response?.data?.talk);
        setRelatedTalks(response?.data?.relatedTalks);
        setLoader(false);
    }

    const updateScreenSize = () => {
        // setScreenWidth(window.innerWidth);
        // setScreenHeight(window.innerHeight);
    };

    const onEpisodeChange = (url: string) => {
        setIframeURL(url);
    };

    const handleServerChange = (value: string) => {
        setIframeURL(value);
      };
    
    useEffect(() => {
        window.scrollTo(0, 0);
        getVideoDetails();
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [iframeURL]);
  
    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('resize', updateScreenSize);
        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    return (
        <>
            {!matches && <Row  style={styles.searchBarContainer}>
                <Col span={22}>
                    <SearchBar/>
                </Col>
            </Row>}
            <Row gutter={22} style={{display: 'flex', justifyContent: 'center'}}>
                <Col span={24}>
                    <Row gutter={24}>
                        <Col span={24}  xs={24} sm={24} style={styles.card}>
                            <div>
                                <p className='category-row'>
                                    {<Link to={'/'} className='category-link'>Home / 
                                    </Link>}
                                   <Link to={`/videos/${videoDetails?.category}`} 
                                        className='category-link'> {videoDetails?.category}
                                    </Link>
                                    <span className='category-link'> / {videoDetails?.title}</span>
                                </p>
                                {videoDetails?.videoId ? <VideoPlayer videoId={videoDetails?.videoId}></VideoPlayer> :
                                <>
                                    {videoDetails?.iframeURL && videoDetails?.embedUrl && <div className='category-row'>
                                        <Space wrap>
                                            <Select
                                                defaultValue={'Server 1'}
                                                style={{ width: 200 }} // Increase width for better aesthetics
                                                onChange={handleServerChange}
                                                className="custom-select" // Add custom class
                                                options={[
                                                {
                                                    label: 'Server 1',
                                                    value: videoDetails?.embedUrl,
                                                },
                                                {
                                                    label: 'Server 2',
                                                    value: videoDetails?.embedUrlTmdb,
                                                },
                                                {
                                                    label: 'Server 3',
                                                    value: videoDetails?.iframeURL,
                                                },
                                                ]}
                                            />
                                            </Space>

                                    </div>}
                                    <IFramePlayer url={iframeURL}></IFramePlayer>
                                </>}
                            </div>
                            {/* <Row>
                                <Typography.Title level={matches ? 2 : 4}>{videoDetails?.title}</Typography.Title>
                                <p style={{fontSize: '1rem'}}>{videoDetails?.description}</p>
                                <br />
                                <Typography.Text>{videoDetails?.views} Views since {dayjs(videoDetails?.publishedAt).format('MMM YYYY')}</Typography.Text>
                                <Row>
                                    {videoDetails?.tags?.map((tag: string, index: number)=>{
                                        return <Tag key={index} style={styles.Tag}>#{tag}</Tag>
                                    })}
                                </Row>
                            </Row> */}
                        </Col>
                    </Row>
                </Col>
                {loader ? <Loader /> : <></>}
            </Row>
            <div style={{margin: '2%'}}>
                <Episodes data={videoDetails} onEpisodeChange={onEpisodeChange}></Episodes>
                <Title level={3}>Recommanded</Title>
                <br />
                <Row gutter={24}>
                        {relatedTalks?.map((video: talksInterface, index: number)=>{
                            // Don'tchange atag to Link.
                            return <Col key={index} span={matches ? 4: 8}  xs={matches ? 24: 12} sm={matches ? 4: 12} style={styles.card} className='video-container'> 
                                <Link to={`/videos/${video?.category}/${video?.title}-${video?._id}`}>
                                {/* <Row gutter={24}> */}
                                    {/* <Col span={24}  xs={24} sm={6} style={styles.card} className='video-container'> */}
                                        <Tooltip title={`Watch ${video?.title}`}>
                                            <Image 
                                            className='movie-image' 
                                            style={matches ? styles.relatedVideosImage: styles.relatedVideosImageMobile} 
                                            src={video?.thumbnail} 
                                            alt={video?.title} 
                                            preview={false}
                                            />
                                        </Tooltip>
                                        {<div className='episode-icon' style={{left:'16px'}}>
                                            {video?.episodes?.length > 0 ? `EP ${video?.episodes?.length}`: 'HD'}
                                        </div>}
                                        <div 
                                        className='play-button'
                                        ></div>
                                    {/* </Col> */}
                                    {/* <Col span={24}  xs={12} sm={12} style={styles.card}>
                                        <Typography.Text strong>{video?.title}</Typography.Text>
                                        <br />
                                        <Typography.Text>{video?.channelTitle} <CheckCircleOutlined /></Typography.Text>
                                        <br />
                                        <Typography.Text>{video?.views} views | {dayjs(video?.publishedAt).format('MMM YYYY')}</Typography.Text>
                                    </Col> */}
                                {/* </Row> */}
                                </Link>
                                </Col>
                        })}            
                        </Row>                            
                <Row gutter={24}>
                    <Comments></Comments>
                </Row>
            </div>
        </>
    );
};
