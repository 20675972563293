/* eslint-disable camelcase */
import {
    Row,
    Col,
  } from 'antd';
  // import TypographyTitle from '../../components/common/Title';
  import {
    InfoCircleOutlined,
  } from '@ant-design/icons';
  import './index.css'
  import { Link } from 'react-router-dom';
  import { getMatches } from '../../apis/fixture.api';
  import { useEffect, useState } from 'react';
  import Loader from '../../components/common/Loader';
  import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { getTimer } from '../../utils/timer';
import { getPageTitle } from '../../utils/pageTitle';

  
    const headerStyle: any = {
      display: 'flex',
      justifyContent: 'flex-start',
    };
  
    const iconStyle = {
      color: '#adc6ff',
      marginRight: '10px',
      fontSize: '21px',
      strokeWidth: '15px',
      alignItems: 'middle'
    };
  
  export default  function  Sports() {
    const matches = useMediaQuery('(min-width: 768px)');
    const [fixtures, setFixtures] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
  
    const getAllFixtures = async ()=> {
      setLoader(true);
      const response = await getMatches();
      setFixtures(response.data);
      setLoader(false);
    }
  
    function CategoryCard({ category }: any) {
      return (
        <>
        <Col style={{ marginBottom: '8px', justifyContent:'center'}} span={24}>
              {category?.matches?.length > 0 && <div  style={headerStyle}>
                <span style={{display: 'flex', alignItems: 'center', color: 'rgb(152 236 226)', fontSize: '18px', textTransform: 'capitalize',paddingLeft: '10px'}}>
                
                { category?.categoryImage ? 
                <img style={{width: '30px', paddingRight: '10px'}} 
                src={category?.categoryImage} 
                alt={category?.category} 
                />: <InfoCircleOutlined style={iconStyle} />}
                {category?.category.toUpperCase()}
                </span>
              </div>}
          <Row gutter={24}>
            {category?.matches?.length > 0 && category?.matches?.map((fixture: any, index: any) => (
            <Col span={24} key={index}>
              <FixtureCard fixture={{...fixture, subCategoryName: fixture?.subCategoryName}} />
            </Col>
            ))}
          </Row>
  
        </Col>
        </>
      );
    }

    function Timer({ timerValue }: any) {
        return (
          <strong style={timerValue === 'Live' ? { color: 'red' } : {}}>{timerValue}</strong>
        );
      }
  
    function FixtureCard({ fixture }: any) {
      return (
        <Link 
        to={`/sports/${fixture?.teams}/${fixture?.streamId}`}
        rel="noopener noreferrer"
        >
          <div className='fixture-card'>
                <img src={fixture?.teamAImage} alt="" style={{ margin: '5px', maxWidth:matches ? '60px': '25px'}}/>
                <div className='flex-display'>
                    {matches && <>
                        <p className='category'>{fixture?.subCategoryName?.toUpperCase()} </p>
                        <span className='dash'></span>
                    </>}
                    <p className='team-name'><strong> {fixture?.teams}</strong>{' '}</p>
                    {/* {fixture?.teamB ? <p className='verses'>vs</p>: ''}
                    <p className='team-name'><strong>{fixture.teamB}</strong>{' '}</p> */}
                    <div>
                        <p className='match-time'>
                            <Timer timerValue={getTimer(fixture)}></Timer>
                        </p>
                    </div>
                </div>
                <img src={fixture?.teamBImage} alt="" style={{ margin: '5px', maxWidth:matches ? '60px': '25px'}}/>

          </div>
        </Link>
      );
    }
  
    // const handleButtonClick = () => {
    //   window.open('https://www.buymeacoffee.com/streameast', '_blank');
    // };
  
    useEffect(() => {
      document.title = getPageTitle('sports');
      window.scrollTo(0, 0);
      getAllFixtures();
    }, []);
  
    return (
      <>
        <Row gutter={24} style={{display: 'flex', justifyContent: 'center'}}>
          <Col span={matches ? 18: 24} style={{marginTop: '12px'}}>
            {
              fixtures?.map((category: any, index: any) => (
                <CategoryCard key={index} category={category} />
              )) 
            }
          </Col>
        </Row>
        {loader ? <Loader /> : <></>}
      </>
    );
  }
  